

import React, { useState, useEffect } from 'react';
import config from '../../Theme/ThemeConfig';

import play from "../../Images/play.png";
import Backend from '../../Backend';
import InputComp from '../Register/InputComp';

const NewTheme = config.NewTheme
const backend = new Backend()

const Demo = ({ login_demo, closedemo }) => {
    const [loading, setLoading] = useState(false);
    const [otpsent, setOtpSent] = useState(false);
    const [Mobile, setMobile] = useState('');
    const [OtpValue, setOtpValue] = useState('');
    const [resend, setResend] = useState(false);
    const [resendTime, setResendTime] = useState(60);

    useEffect(() => {
        const interval = setInterval(() => {
            if (resendTime === 0) {
                setResend(true);
            } else {
                if (otpsent === true) {
                    setResendTime((prevTime) => prevTime - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [resendTime, otpsent]);

    const getOtp = () => {
        const data = {
            mobile: Mobile,
            // otpvalue: OtpValue,
        };
        backend.get_otp(data).then(r => {
            setLoading(false);
            if (r.error == 'False') {
                setOtpSent(true);
            } else {
                alert(r.message);
            }
        });
    };

    const Find_Otp = () => {
        const data = {
            mobile: Mobile,
            // otpvalue: OtpValue,
        };
        backend.find_otp(data).then(r => {
            setLoading(false);
            if (r.error == 'False') {
                alert(

                    'ENTER ' + r.otp + ' As OTP VALUE TO COMPLETE DEMO CREATION',
                );
                // setotpsent(true);
            } else {
                alert(r.message);
            }
        });
    };

    const getDemo = () => {
        const data = {
            mobile: Mobile,
            otpvalue: OtpValue,
        };
        backend.get_demo(data).then(r => {
            setLoading(false);
            if (r.error == 'False') {
                setTimeout(() => {
                    login_demo(r.data.server, r.data.username, r.data.username);
                }, 200);
            } else {
                alert(r.message);
            }
        });
    };




    return (


        <div
            className="Login_Container"

            style={{ background: "white", flexDirection: 'column' }}
        >

            <div style={{ display: 'flex', height: 50, zIndex: 99, borderColor: 'gray', alignItems: 'center', width: '100%' }}>
                <div onClick={closedemo} style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <img src={play} style={{ width: 20, height: 20, transform: 'rotate(180deg)' }} alt="" />
                </div>
                <div style={{ flex: 1 }}>Create Demo Account</div>
            </div>

            <div style={{ width: '100%', alignSelf: 'center', padding: 5, paddingLeft: 10, paddingRight: 10 }}>

                <InputComp
                    disable={otpsent}
                    type="number"
                    placeholder="eg. 98xxx98xxx"
                    text="Mobile Number"
                    onChangeText={setMobile}
                />
                {otpsent && (
                    <InputComp
                        disable={otpsent}
                        type="number"
                        placeholder="eg. x7x7"
                        text="Enetr Otp"
                        onChangeText={setOtpValue}
                    />
                )}


                {otpsent && (<div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <p style={{ color: NewTheme.MainColor }}>OTP WAITING TIME {resendTime} Sec.</p>

                    {resend && (
                        <div onClick={Find_Otp}>Didn't Receive OTP? Click Here!</div>
                    )}
                </div>)}
                <div style={{ display: 'flex', width: '100%' }}>

                    {!otpsent && (
                        <button
                            onClick={getOtp}
                            style={{ background: NewTheme.MainColor }}

                            className="login_btn"
                        >
                            Request Otp
                        </button>)}


                    {otpsent && (<button

                        onClick={getDemo}
                        style={{ background: NewTheme.MainColor }}

                        className="login_btn"
                    >
                        Get Demo Account
                    </button>)}
                </div>
            </div>
        </div>

    );
};

export default Demo;


