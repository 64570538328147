import React from "react";
// import { NewTheme } from "../../Theme/Theme";
import { FaCircleArrowDown } from "react-icons/fa6";
import moment from "moment";
import config from "../../Theme/ThemeConfig";
const NewTheme = config.NewTheme

export default function LedgerCard(props) {
  const i = props.data;
  console.log(i);
  return (
    <div
      style={{
        background: "white",
        display: "flex",
        padding: 10,
        boxSizing: "border-box",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        // borderColor: "gray",
        // borderBottomWidth: props.length == props.index ? 1 : 0,
      }}
    >
      <div
        style={{
          width: 50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FaCircleArrowDown
          size={30}
          style={{
            color: i.in_out == "0" ? NewTheme.greencolor : NewTheme.redcolor,
            transform: i.in_out == 0 ? "rotate(0deg)" : "rotate(180deg)",
          }}
        />
        <div style={{ fontWeight: "bold", fontSize: 11 }}>{i.qty}</div>
      </div>
      <div style={{ flex: 2 }}>
        <div style={{ fontWeight: "bold", fontSize: 14, display: "flex" }}>
          {i.remarks}
        </div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 12,
            display: "flex",
            color: "gray",
          }}
        >
          Balance:- {i.new_balance}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontSize: 16,
            fontWeight: "bold",
            color: i.amount < 0 ? NewTheme.redcolor : NewTheme.greencolor,
          }}
        >
          {parseFloat(i.amount).toFixed(0)}
        </div>

        <div
          style={{
            fontSize: 11,
            fontWeight: "bold",
            color: "gray",
          }}
        >
          {moment(i.date_created).format("hh:mm:ss A")}
        </div>
      </div>
    </div>
  );
}
