import React, { useState } from 'react'
import config from '../../Theme/ThemeConfig'
import { FaFolderOpen } from 'react-icons/fa6'
import Searchcard2 from './SearchCard2'
import { useWindowSize } from '@uidotdev/usehooks'

const NewTheme = config.NewTheme

export default function Segments({ data, name, t }) {
    const [show, setShow] = useState(false)
    const { height, width } = useWindowSize();
    const heights = height / 100 * 50
    return (
        <>
            <div onClick={() => setShow(!show)} style={{ display: 'flex', height: 40 }}>
                <FaFolderOpen color={NewTheme.MainColor} style={{ marginRight: 15 }} size={25} />
                <div style={{ fontSize: 18, fontWeight: 'bold', color: NewTheme.MainColor }}>{name}</div>
                <div style={{ fontSize: 18, fontWeight: 'bold', color: NewTheme.MainColor }}>({data.length})</div>
            </div>

            <div style={{ overflowY: 'scroll', height: show ? heights : 'unset' }}>
                {
                    show ?
                        data.map((item, index) => (
                            <Searchcard2 key={item.script_id} data={item} index={index} />
                        )) : null}
            </div>

        </>

    )
}
