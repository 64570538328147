import { useWindowSize } from "@uidotdev/usehooks";
import React, { useEffect, useState } from "react";
import play from "../../Images/play.png";
import config from "../../Theme/ThemeConfig";
// import { NewTheme } from "../../Theme/Theme";
const NewTheme = config.NewTheme

export default function News() {
  const { height } = useWindowSize();
  const [news, setNews] = useState([]);
  useEffect(() => {
    if (localStorage.getItem("news") !== null) {
      var array = JSON.parse(localStorage.getItem("news"));
      setNews(array);

      var max = 0;

      const data = array.map((i) => {
        if (i.id > max) {
          max = i.id;
        }
      });

      Promise.all(data).then((r) => {
        localStorage.setItem("setid", max);
      });
    }
  }, []);
  return (
    <div
      className="Ledger_Container"
      style={{ background: "white", paddingTop: 10 }}
    >
      <div
        className="top_bar"
        style={{
          position: "fixed",
          background: "white",
          top: 0,
          paddingTop: 30,
          width: "100%",
          paddingBottom: 30,
        }}
        onClick={() => (window.location = "/")}
      >
        <img src={play} className="top_back_icon" alt="" />
        <p className="title_text">News Bill</p>
      </div>

      <div
        style={{
          width: "100%",
          height: height - 30,
          overflowY: "scroll",
          marginTop: 50,
          padding: 5,
        }}
      >
        {news.map((i, t) => (
          <div
            key={i.id}
            style={{
              borderStyle: "solid",
              marginTop: 5,
              borderWidth: 1,
              padding: 10,
              borderRadius: 5,
              borderColor: NewTheme.MainColor,
            }}
          >
            <div
              style={{
                fontSize: 15,
                fontWeight: "bold",
                color: NewTheme.MainColor,
              }}
            >
              {i.news_type}
            </div>
            <div style={{ fontWeight: "bold", fontSize: 13 }}> {i.news}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
