import React, { useState } from "react";
// import { NewTheme } from "../../Theme/Theme";
import { useSelector } from "react-redux";
import config from "../../Theme/ThemeConfig";
// import logo from "../../logo.svg";

const NewTheme = config.NewTheme;
export default function TopCardPos(props) {
  const total = useSelector((state) => state.total);
  const [show, setShow] = useState(false)


  var margin = 0;

  margin = parseFloat(props.balance) + parseFloat(total);

  margin = margin * props.multiplier;

  margin = parseFloat(margin) - parseFloat(props.limit);

  var balance = parseFloat(parseFloat(props.balance) + total).toFixed(1);

  const numDifferentiation = (val) => {
    var data = 0
    if (val >= 10000000 || val <= -10000000) {
      data = (Math.abs(val) / 10000000).toFixed(2) + " Cr";
    } else if (val >= 100000 || val <= -100000) {
      data = (Math.abs(val) / 100000).toFixed(2) + " Lac";
    } else if (val >= 1000 || val <= -1000) {
      data = (Math.abs(val) / 1000).toFixed(2) + " K";
    }
    return (val < 0 ? "-" : "") + data;
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        background: NewTheme.MainColor,
        paddingBottom: 20,
      }}
    >
      <div
        style={{
          // background: "red",
          zIndex: 999,
          width: "90%",
          marginTop: 20,
          display: "flex",
          flexDirection: "column",
          borderStyle: "solid",
          // borderWidth: 1,
          borderColor: NewTheme.MainColor,
        }}
      >
        <div
          style={{
            width: "100%",
            flex: 1,
            background: "white",
            borderStyle: "solid",
            borderWidth: 0,
            // borderBottomWidth: 1,

            display: "flex",
            padding: 5,
            borderRadius: 3,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderStyle: "solid",
              borderWidth: 0,
              // borderRightWidth: 1,
              height: "80%",
              alignSelf: "center",
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: 11 }}>BOOKED</div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 16,
                color:
                  props.booked >= 0 ? NewTheme.MainColor : NewTheme.redcolor,
              }}
            >
              {props.loading
                ? 0
                : props.booked == null
                  ? 0
                  : parseFloat(props.booked).toFixed(2)}
            </div>
          </div>

          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderStyle: "solid",
              borderWidth: 0,
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: 11 }}>BALANCE</div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              {props.balance == null ? 0 : balance}
            </div>
          </div>
        </div>



        <div
          style={{
            width: "100%",
            flex: 1,
            background: "white",
            borderStyle: "solid",
            borderWidth: 0,

            display: "flex",
            marginTop: 5,
            padding: 5,
            borderRadius: 3,
          }}
        >
          <div
            style={{
              flex: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderStyle: "solid",
              borderWidth: 0,
              borderRightWidth: 1,
              height: "80%",
              alignSelf: "center",
            }}
          >
            {localStorage.getItem("server").toUpperCase() == "TEST" ? (
              <div style={{ fontSize: 12, fontWeight: "bold", color: "red" }}>
                DEMO ACCOUNT
              </div>
            ) : null}
            <div style={{ fontWeight: "bold", fontSize: 11 }}>
              LIVE PROFIT LOSS
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 20,
                color: total >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
              }}
            >
              {props.loading ? 0 : parseFloat(total).toFixed(2)}
            </div>
          </div>

          <div
            style={{
              flex: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              borderStyle: "solid",
              borderWidth: 0,

              // borderRightWidth: 1,
            }}
          >
            <button
              onClick={() => props.sqoffall()}
              style={{
                height: 40,
                borderRadius: 5,
                background: NewTheme.MainColor,
                color: "white",
                fontWeight: "bold",
                borderWidth: 0,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              SQ-OFF ALL
            </button>
            <button
              onClick={() => setShow(!show)}
              style={{
                height: 40,
                borderRadius: 5,
                background: NewTheme.MainColor,
                color: "white",
                fontWeight: "bold",
                borderWidth: 0,
                paddingLeft: 10,
                paddingRight: 10,
                marginLeft: 10,
              }}
            >
              M
            </button>
          </div>
        </div>

        {show &&
          <div
            style={{
              width: "100%",
              flex: 1,
              background: "white",
              borderStyle: "solid",
              borderWidth: 0,

              display: "flex",
              flexDirection: 'column',
              marginTop: 5,
              padding: 5,
              borderRadius: 3,
            }}
          >

            {props?.multiplier.map((i3, t3) => (
              <div key={t3} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 5 }}>
                <span style={{ textAlign: 'center', fontWeight: 'bold' }}>{i3.name}</span>
                <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', margin: '0 10px', display: 'flex' }}>
                  <div style={{ borderStyle: 'dashed', borderColor: 'gray', borderWidth: 1, width: '100%', height: 1 }} />
                </div>
                <span style={{ textAlign: 'center', fontWeight: 'bold', color: NewTheme.MainColor }}>
                  {numDifferentiation((balance * i3.leverage) - props.limit)}
                </span>
              </div>
            ))}

          </div>
        }
      </div>
    </div>
  );
}
