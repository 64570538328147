
import React, { useEffect } from 'react';


import { connect } from 'react-redux';
import Backend from '../../Backend';
const backend = new Backend();

function Wallet(props) {
    const { setwallet } = props;
    // const navigation = useNavigation();
    const load_wallet = () => {
        const data = {
            sv: localStorage.getItem("server"),
            id: localStorage.getItem("client"),
            jwt: localStorage.getItem("token"),
        };
        backend.wallet(data).then(r => {

            if (r.error == "False") {
                setwallet(r.data);
            } else {
                if (r.error == "Logout") {
                    localStorage.clear()
                    window.location = "/"
                }
                alert(r.message)
            }

        });

        // setwallet({bal: 120, link: 'http://web.whatsapp.com'});
    };
    useEffect(() => {
        load_wallet();
        // const willFocusSubscription = navigation.addListener('focus', () => {
        //     load_wallet();
        // });
        // return () => {
        //     willFocusSubscription;
        //     // Anything in here is fired on component unmount.
        // };
    }, []);
    return null;
}

const MapStateToProps = state => {
    return {
        id: state.id,
        token: state.token,
        server: state.server,
        theme: state.theme,
    };
};
export default connect(MapStateToProps)(Wallet);
