import { React, useState, useEffect } from "react";
// import logo from "../logo.svg";
import "./Account.css";

import Backend from "../../Backend";
import play from "../../Images/play.png";

import logo from "../../Images/logo.png";
import ruppee from "../../Images/rupee.png";
import database from "../../Images/database.png";
import profile from "../../Images/profile-user.png";
import bank from "../../Images/bank.png";
import money from "../../Images/money.png";
import stock from "../../Images/stock-market.png";
import gold from "../../Images/gold-ingot.png";
import candle from "../../Images/candlestick.png";

import moment from "moment";
// import { NewTheme } from "../../Theme/Theme";
import config from "../../Theme/ThemeConfig";

import { useNavigate } from "react-router-dom";

const backend = new Backend();
const NewTheme = config.NewTheme;
const Account = () => {
  const navigate = useNavigate();
  const logoutHandler = () => {
    localStorage.removeItem("IsLoggedIn");
    localStorage.removeItem("token");
    localStorage.removeItem("rules");
    localStorage.removeItem("server");
    localStorage.removeItem("client");
    window.location = "/";
  };
  const [loading, setLoading] = useState(false);
  const [profiledata, setProfiledata] = useState([]);
  const [brokerages, setBrokerages] = useState([]);
  const [multiplier, setMultiplier] = useState([]);

  const [newdata, setNewdata] = useState([])

  useEffect(() => {
    loadprofile();
  }, []);
  const loadprofile = () => {
    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
    };

    try {
      backend.loadprofile(data).then((r) => {
        setTimeout(() => {
          setLoading(false);
          // refRBSheet.current.open();
        }, 500);
        // console.log(r);

        if (r.error == "False") {
          setProfiledata(r.data);
          setNewdata(r.newdata)
          setBrokerages(JSON.parse(r.data.brokerages));
          setMultiplier(JSON.parse(r.data.multiplier));
        }
        // console.log(r);
      });
    } catch { }
  };

  return (
    <div
      className="Account_Container"
      style={{ background: "white", paddingTop: 10 }}
    >
      <div
        className="top_bar"
        style={{
          position: "fixed",
          background: "white",
          top: 0,
          paddingTop: 30,
          width: "100%",
          paddingBottom: 30,
        }}
        onClick={() => navigate(-1)}
      >
        <img src={play} className="top_back_icon" alt="" />
        <p className="title_text">Account Details</p>
      </div>

      <div className="details_box" style={{ paddingBottom: 10, marginTop: 70 }}>
        <img
          style={{
            height: 300,
            width: 300,
          }}
          src={config.login}
          alt=""
          className="list_icon"
        />
        <p className="detail_title">Summary</p>


        <div className="detail_item">
          <img src={ruppee} alt="" className="detail_icon" />
          <p className="detail_text">BALANCE</p>
          {/* <img src={logo} alt="" className="detail_next_icon" /> */}
          <p>{parseFloat(profiledata.deposit).toFixed(2)}</p>
        </div>
        <div className="detail_item">
          <img src={database} alt="" className="detail_icon" />
          <p className="detail_text">SERVER</p>
          <p>{profiledata.server}</p>
          {/* <img src={logo} alt="" className="detail_next_icon" /> */}
        </div>
        <div className="detail_divider" />
        <p className="detail_title">Account Details</p>



        <div className="detail_item">
          <img src={profile} alt="" className="detail_icon" />
          <p className="detail_text">A/C DATE</p>
          <p>{moment(profiledata.date_created).format("DD-MMM-YY")}</p>
          {/* <img src={logo} alt="" className="detail_next_icon" /> */}
        </div>
        <div className="detail_item">
          <img src={bank} alt="" className="detail_icon" />
          <p className="detail_text">TOTAL LIMIT</p>
          <p>{parseFloat(profiledata.total_limit).toFixed(0)}</p>
          {/* <img src={logo} alt="" className="detail_next_icon" /> */}
        </div>
        <div className="detail_item">
          <img src={money} alt="" className="detail_icon" />
          <p className="detail_text">MARGIN AMOUNT</p>
          <p>{parseFloat(profiledata.total_limit).toFixed(0)}</p>
          {/* <img src={logo} alt="" className="detail_next_icon" /> */}
        </div>

        <div className="detail_divider" />

        <p className="detail_title">Brokerage Details</p>

        {newdata.map((i, t) => (
          <div key={"brok" + t} className="detail_item">
            <img src={i.icon} alt="" className="detail_icon" />
            <p className="detail_text">{i.name}</p>
            <p>{i.brokerage}</p>
            {/* <img src={logo} alt="" className="detail_next_icon" /> */}
          </div>
        ))}



        <div className="detail_divider" />

        <p className="detail_title">Leverage Details</p>

        {newdata.map((i, t) => (
          <div key={"lev" + t} className="detail_item">
            <img src={i.icon} alt="" className="detail_icon" />
            <p className="detail_text">{i.name}</p>
            <p>{i.leverage}</p>
            {/* <img src={logo} alt="" className="detail_next_icon" /> */}
          </div>
        ))}

        <div className="detail_divider" />

        <p className="detail_title">Carry Forward Margin</p>

        {newdata.map((i, t) => (
          <div key={"cf_" + t} className="detail_item">
            <img src={i.icon} alt="" className="detail_icon" />
            <p className="detail_text">{i.name}</p>
            <p>{i.cf_margin}</p>
            {/* <img src={logo} alt="" className="detail_next_icon" /> */}
          </div>
        ))}


        {/* <div className="detail_divider"/> */}
      </div>
      <div style={{ height: 100 }}></div>
    </div>
  );
};

export default Account;
