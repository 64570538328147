import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Backend from '../../Backend';
import play from "../../Images/play.png";
import config from '../../Theme/ThemeConfig';
import InputComp from '../Register/InputComp';
import moment from 'moment';

const backend = new Backend()
const NewTheme = config.NewTheme

export default function Withdraw() {

    const [type, setType] = useState('UPI');
    const [Upi, setUpi] = useState('');
    const [history, sethistory] = useState(false);
    const [Amount, setAmount] = useState('');
    const [BankNAme, setBankNAme] = useState('');
    const [IfscCode, setIfscCode] = useState('');
    const [AccountNumber, setAccountNumber] = useState('');
    const [ReAccountNumber, setReAccountNumber] = useState('');
    const [Benificiary, setBenificiary] = useState('');
    const [Withdrawdata, setWithdrawdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()


    useEffect(() => {
        Load_withdraw();
    }, []);

    const Load_withdraw = () => {
        let data = {
            sv: localStorage.getItem("server"),
            id: localStorage.getItem("client"),
            jwt: localStorage.getItem("token"),
        };

        backend
            .load_withdraw(data)
            .then(r => {
                console.log(r)
                setWithdrawdata(r.data);
            })
            .catch(err => {
                alert(err);
            });
    };

    const Withdraw_request = () => {


        // setLoading(true);
        if (AccountNumber !== ReAccountNumber) {
            alert('Acoount Number Do Not Match');
        } else {
            setLoading(true);
            let data = {
                sv: localStorage.getItem("server"),
                id: localStorage.getItem("client"),
                jwt: localStorage.getItem("token"),
                withdraw_type: type,
                upi_id: Upi,
                bank_name: BankNAme,
                bank_ifsc: IfscCode,
                account_no: AccountNumber,
                account_name: Benificiary,
                amount: Amount,
            };


            backend
                .create_withdraw(data)
                .then(r => {
                    setTimeout(() => {
                        setLoading(false);
                        // refRBSheet.current.open();
                    }, 500);

                    Load_withdraw();

                    // if (r.error == 'False') {
                    alert(r.message);
                    // }
                })
                .catch(err => {
                    setTimeout(() => {
                        setLoading(false);
                        // refRBSheet.current.open();
                    }, 500);
                });
        }

        // console.log(r);
    };



    return (
        <div
            className="Login_Container"

            style={{ background: "white", flexDirection: 'column' }}
        >

            <div style={{ display: 'flex', height: 50, zIndex: 99, borderColor: 'gray', alignItems: 'center', width: '100%' }}>
                <div onClick={() => navigate(-1)} style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <img src={play} style={{ width: 20, height: 20, transform: 'rotate(180deg)' }} alt="" />
                </div>
                <div style={{ flex: 1 }}>Withrawal Request</div>
            </div>



            <div style={{ width: '100%', alignSelf: 'center', padding: 5, paddingLeft: 10, paddingRight: 10 }}>

                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <button
                        onClick={() => setType("UPI")}
                        style={{ background: type == "UPI" ? NewTheme.MainColor : 'gray', width: '70%' }}

                        className="login_btn"
                    >
                        UPI
                    </button>
                    <div style={{ width: '20%' }}></div>
                    <button
                        onClick={() => setType("BANK")}
                        style={{ background: type == "BANK" ? NewTheme.MainColor : "gray", width: '70%' }}
                        className="login_btn"
                    >
                        Bank Account
                    </button>
                </div>

                {Withdrawdata?.length > 0 ?
                    <div
                        onClick={() => sethistory(!history)}
                        style={{ width: '100%', textAlign: 'center', marginTop: 5, marginBottom: 5, padding: 5, background: NewTheme.MainColor, color: 'white' }}>Pending Withdrawal Request ({Withdrawdata?.length})</div> : null}

                {!history ? <>                {type == "UPI" && (<InputComp
                    // disable={otpsent}
                    type="text"
                    placeholder="eg. abcz@okhdfcbank"
                    text="Upi ID"
                    onChangeText={setUpi}
                />)}


                    {type == "BANK" && (<InputComp
                        // disable={otpsent}
                        type="text"
                        placeholder="eg. john snow"
                        text="Benificiary Name"
                        onChangeText={setBenificiary}
                    />)}
                    {type == "BANK" && (<InputComp
                        // disable={otpsent}
                        type="text"
                        placeholder="eg. HDFC BANK LTD"
                        text="BANK NAME"
                        onChangeText={setBankNAme}
                    />)}
                    {type == "BANK" && (<InputComp
                        // disable={otpsent}
                        type="text"
                        placeholder="eg. HDFC0000XXX"
                        text="IFSCCODE"
                        onChangeText={setIfscCode}
                    />)}
                    {type == "BANK" && (<InputComp
                        // disable={otpsent}
                        type="text"
                        placeholder="eg. 05xxx04034"
                        text="Account Number"
                        onChangeText={setAccountNumber}
                    />)}
                    {type == "BANK" && (<InputComp
                        // disable={otpsent}
                        type="text"
                        placeholder="eg. 05xxx04034"
                        text="Re-Enter Account Number"
                        onChangeText={setReAccountNumber}
                    />)}



                    <InputComp
                        // disable={otpsent}
                        type="number"
                        placeholder="eg. 20.00"
                        text="Enter Amount"
                        onChangeText={setAmount}
                    />

                    <button
                        onClick={Withdraw_request}
                        style={{ background: NewTheme.MainColor }}
                        className="login_btn"
                    >
                        Submit
                    </button>
                </> :

                    <>

                        <div style={{ width: '100%' }}>
                            {Withdrawdata?.map((i, t) => (
                                <div style={{ borderStyle: 'solid', padding: 5, borderRadius: 5, borderWidth: 0.5, display: 'flex', marginBottom: 10 }}>

                                    <div style={{ flex: 1 }}>

                                        <div style={{ fontSize: 12 }}>TYPE</div>
                                        <div style={{ color: NewTheme.MainColor }}>{i.withdraw_type}</div>

                                        <div style={{ fontSize: 12 }}>Amount</div>
                                        <div style={{ color: NewTheme.MainColor }}>{i.amount}</div>
                                    </div>
                                    <div style={{ flex: 1 }}>


                                        <div style={{ fontSize: 12 }}>{i.withdraw_type == "UPI" ? "UPI " : "BANK "}Details</div>

                                        {i.withdraw_type == "UPI" ?
                                            <div style={{ color: NewTheme.MainColor }}>{i.upi_id}</div>
                                            : <>
                                                <div style={{ color: NewTheme.MainColor }}>{i.bank_beneficiary}</div>
                                                <div style={{ color: NewTheme.MainColor }}>{i.bank_name}</div>
                                                <div style={{ color: NewTheme.MainColor }}>{i.bank_ifsc}</div>
                                                <div style={{ color: NewTheme.MainColor }}>{i.bank_acc_no}</div>

                                            </>}

                                        <div style={{ fontSize: 12 }}>Date</div>
                                        <div style={{ color: NewTheme.MainColor, fontSize: 13 }}>{moment(i.date_created).format("DD-MM-YYYY hh:mm:ss A")}</div>
                                    </div>
                                </div>
                            ))}


                        </div>

                    </>
                }

            </div>

        </div >
    )
}
