import React from "react";
import "./Marquee.css"; // Import the CSS file for styling
import config from "../../Theme/ThemeConfig";
// import { NewTheme } from "../../Theme/Theme";
const NewTheme = config.NewTheme

class Marquee extends React.Component {
  render() {
    return (
      <div className="marquee-container">
        <div
          className="marquee-content"
          style={{
            fontSize: 17,
            fontWeight: "bold",
            color: NewTheme.redcolor,
          }}
        >
          {/* Your content goes here */}
          THIS APPLICATION IS FOR DEMO PURPOSE & EDUCATIONAL PURPOSE ONLY , NO
          REAL MONEY INVOLVED.
        </div>
      </div>
    );
  }
}

export default Marquee;
