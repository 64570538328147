import React from "react";
import { FaCircleArrowDown } from "react-icons/fa6";
// import { NewTheme } from "../../Theme/Theme";
import moment from "moment";
import config from "../../Theme/ThemeConfig";

const NewTheme = config.NewTheme

export default function RejecttionCard(props) {
  const i = props.data;
  const rejection = JSON.parse(i.rejection_array);
  console.log(i);
  return (
    <div
      style={{
        background: "white",
        display: "flex",
        padding: 10,
        boxSizing: "border-box",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        // borderColor: "gray",
        // borderBottomWidth: props.length == props.index ? 1 : 0,
      }}
    >
      <div
        style={{
          width: 60,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: i.type == 0 ? NewTheme.greencolor : NewTheme.redcolor,
          fontSize: 12,
        }}
      >
        <div
          style={{
            borderStyle: "solid",
            width: 45,
            height: 45,
            borderWidth: 1,
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          {i.type == 0 ? "BUY" : "SELL"}
        </div>
      </div>
      <div style={{ flex: 2 }}>
        <div style={{ fontWeight: "bold", fontSize: 14, display: "flex" }}>
          {i.symbol}
        </div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 12,
            display: "flex",
            color: "gray",
          }}
        >
          {rejection.msg}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontSize: 16,
            fontWeight: "bold",
            color: i.type != 0 ? NewTheme.redcolor : NewTheme.greencolor,
          }}
        >
          {i.type == 1 ? "-" : ""}
          {parseFloat(i.qty).toFixed(2)}
        </div>

        <div
          style={{
            fontSize: 11,
            fontWeight: "bold",
            color: "gray",
            alignSelf: "flex-end",
          }}
        >
          {moment(i.date_created).format("DD-MMM HH:mm:ss")}
        </div>
      </div>
    </div>
  );
}
