import "./StockDetails.css";
import logo from "../../logo.svg";
import play from "../../Images/play.png";
import minus from "../../Images/minus.png";
import { React, useState, useEffect, useContext } from "react";
import SocketContext from "../../SocketContext";
import moment from "moment";
import { FaCircleArrowUp, FaCircleArrowDown } from "react-icons/fa6";

import Backend from "../../Backend";
// import { NewTheme } from "../../Theme/Theme";
import { InputText } from "primereact/inputtext";
import config from "../../Theme/ThemeConfig";

import { useNavigate } from "react-router-dom";
const backend = new Backend();

const NewTheme = config.NewTheme;

const StockDetails = () => {
  // console.log("items are here");
  // localStorage.getItem("item");
  // console.log(JSON.parse(localStorage.getItem("item")));
  const item = JSON.parse(localStorage.getItem("item"));
  const i = item;
  // console.log(typeof i);
  // console.log("i");
  // console.log(typeof i.symbol_display);
  // console.log("hello");
  // console.log(props.list);
  const socket = useContext(SocketContext);
  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ask, setask] = useState(0);
  const [askhigh, setaskhigh] = useState(false);
  const [bid, setbid] = useState(0);
  const [bidhigh, setbidhigh] = useState(false);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [ltp, setltp] = useState(0);
  const [ticktime, SetTicktime] = useState(0);
  const [open, setopen] = useState(0);
  const [close, setclose] = useState(0);

  // from buy sell yourtrade
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [qty, setQty] = useState(0);
  const onQtyHandler = (e) => {
    setQty(e.target.value);
  };
  const [option, setOption] = useState(0);
  const [atPrice, setAtPrice] = useState(0);

  const [qtypos, setQtypos] = useState(0);
  const [idpos, setIdpos] = useState(0);

  const [buysell, setBuysell] = useState(2);

  const load_position_overview = () => {
    let newdata = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      script_id: i.script_id,
      segment: i.script_type,
    };

    backend.load_position_overview(newdata).then((r) => {
      // console.log(r);
      if (r.error == "False") {
        setBuysell(r.buy_sell);
        setQtypos(r.qty);
        setIdpos(r.pos_id);
      }
      // console.log(r);
    });
  };

  // const [trade,settrade] = useState("")
  // const
  // setTimeout(() => {
  //   console.log("Helo instdet hte time out");
  // }, 1000);
  // setInterval(() => {
  //   console.log("the time inteval")
  // }, 10000);
  useEffect(() => {
    // loadProfile();
    console.log("Tick time");
    console.log("i");
    socket.emit("giverate", i.script_id);
    socket.on("trade" + i.script_id, trade);
    socket.on("bidask" + i.script_id, bidask);
    console.log("item", item);
    return () => {
      socket.off("trade" + i.script_id, trade);
      socket.off("bidask" + i.script_id, bidask);
    };
  }, []);
  useEffect(() => {
    load_position_overview();
  }, []);

  var trade = (msg) => {
    SetTicktime(msg?.Timestamp);
    if (bid !== msg?.Bid) {
      //   if( parseFloat(bidnew)  < parseFloat(msg?.Bid) ){
      //     // bidUp=1

      //    }else if( parseFloat(bidnew)  > parseFloat(msg?.Bid) ){
      //     //  bidUp=2

      //    }
      console.log("-----");
      console.log(bid);
      console.log(msg?.Bid);
      console.log(typeof bid);
      console.log(typeof msg?.Bid);
      if (parseFloat(bid) < parseFloat(msg?.Bid)) {
        setbidhigh(true);
      }
      if (parseFloat(bid) >= parseFloat(msg?.Bid)) {
        setbidhigh(false);
      }
      console.log(bidhigh);

      setbid(msg?.Bid);

      //   bidnew = msg?.Bid
    }
    if (ask !== msg?.Ask) {
      //   if( parseFloat(asknew)  < parseFloat(msg?.Ask) ){
      // //    askUp=1

      //   }else if( parseFloat(asknew)  > parseFloat(msg?.Ask) ){
      //     // askUp=2

      //   }

      if (parseFloat(ask) < parseFloat(msg?.Ask)) {
        setaskhigh(true);
      }
      if (parseFloat(ask) >= parseFloat(msg?.Ask)) {
        setaskhigh(false);
      }
      console.log(askhigh);
      setask(msg?.Ask);
      //   asknew =msg?.Ask
    }
    if (high2 !== msg?.High) {
      sethigh2(msg?.High);
    }
    if (low2 !== msg?.Low) {
      setlow2(msg?.Low);
    }
    if (msg?.LTP - msg?.Previous_Close !== chng) {
      setchng(msg?.LTP - msg?.Previous_Close);
      setpercent(
        ((msg?.LTP - msg?.Previous_Close) / msg?.Previous_Close) * 100
      );
    }
    if (ltp !== msg?.LTP) {
      setltp(msg?.LTP);
    }

    if (open !== msg?.Open) {
      setopen(msg?.Open);
    }
    if (close !== msg?.Previous_Close) {
      setclose(msg?.Previous_Close);
    }
  };

  var bidask = (msg) => {
    SetTicktime(msg?.Timestamp);
    if (bid !== msg?.Bid) {
      //       if( parseFloat(bidnew)  < parseFloat(msg?.Bid) ){
      //         // bidUp=1
      // //
      //        }else if( parseFloat(bidnew)  > parseFloat(msg?.Bid) ){
      //         //  bidUp=2

      //        }
      //   bidnew = msg?.Bid

      // setBidopsite(true);
      setbid(msg?.Bid);
      // setTimeout(() => {
      //   setBidopsite(false);
      // }, 200);
    }
    if (ask !== msg?.Ask) {
      //   if( parseFloat(asknew)  < parseFloat(msg?.Ask) ){

      //     // askUp=1
      //   }else if( parseFloat(asknew)  > parseFloat(msg?.Ask) ){
      // //    askUp=2

      //   }
      //   asknew = msg?.Ask
      // setAskopsite(true);
      setask(msg?.Ask);

      // setTimeout(() => {
      //   setAskopsite(false);
      // }, 200);
    }
  };

  const BuyFunction = () => {
    if (!loading) {
      if (qty <= 0) {
        window.alert("Qty Require Greater Then 0");
      } else {
        setLoading(true);
        let sendData = {
          m_code: i.script_id,
          order: qty,
          ud: "0",
          segi: i.script_type,
          token: localStorage.getItem("token"),
        };

        backend
          .trade(sendData)
          .then((r) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            load_position_overview();
            if (r.success == "True") {
              // load_position_overview();
              window.alert(r.msg);
            } else {
              window.alert(r.msg);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            console.log(err);
            // Alert.alert('', err);
          });
      }
    }
  };
  const SellFunction = () => {
    if (!loading) {
      if (qty <= 0) {
        window.alert("Qty Require Greater Then 0");
      } else {
        setLoading(true);
        let sendData = {
          m_code: i.script_id,
          order: qty,
          ud: "1",
          segi: i.script_type,
          token: localStorage.getItem("token"),
        };

        console.log(sendData);
        backend
          .trade(sendData)
          .then((r) => {
            console.log(r);
            setTimeout(() => {
              setLoading(false);
            }, 500);
            load_position_overview();
            if (r.success == "True") {
              // load_position_overview();
              window.alert(r.msg);
            } else {
              window.alert(r.msg);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            console.log(err);
            // Alert.alert('', err);
          });
      }
    }
  };

  const BuyPending = () => {
    if (!loading) {
      if (qty <= 0) {
        window.alert("Qty Require Greater Then 0");
      } else if (atPrice <= 0) {
        window.alert("At Price Must Be Greater Then 0");
      } else {
        setLoading(true);
        let sendData = {
          m_code: i.script_id,
          order: qty,
          ud: "0",
          segi: i.script_type,
          at_price: atPrice,
          token: localStorage.getItem("token"),
        };

        backend
          .pending(sendData)
          .then((r) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            // console.log(r);
            load_position_overview();
            if (r.success == "True") {
              window.alert(r.msg);
            } else {
              window.alert(r.msg);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            console.log(err);
            // Alert.alert('', err);
          });
      }
    }
  };

  const SellPending = () => {
    if (!loading) {
      if (qty <= 0) {
        window.alert("Qty Require Greater Then 0");
      } else if (atPrice <= 0) {
        window.alert("At Price Must Be Greater Then 0");
      } else {
        setLoading(true);
        let sendData = {
          m_code: i.script_id,
          order: qty,
          ud: "1",
          segi: i.script_type,
          at_price: atPrice,
          token: localStorage.getItem("token"),
        };

        backend
          .pending(sendData)
          .then((r) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            load_position_overview();
            // console.log(r);
            if (r.success == "True") {
              window.alert(r.msg);
            } else {
              window.alert(r.msg);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            console.log(err);
            // Alert.alert('', err);
          });
      }
    }
  };

  const PosClose = () => {
    if (!loading) {
      if (qtypos <= 0) {
        window.alert("Qty Require Greater Then 0");
      } else {
        var check = window.confirm("ARE YOU SURE CLOSE ALL QUANTITY");
        if (!check) {
          return false;
        }

        setLoading(true);
        let sendData = {
          m_code: i.script_id,
          order: qtypos,
          ud: buysell == 1 ? "0" : "1",
          segi: i.script_type,
          token: localStorage.getItem("token"),
        };

        backend
          .sqoffposition(sendData)
          .then((r) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            if (r.success == "True") {
              load_position_overview();
              window.alert(r.msg);
            } else {
              window.alert(r.msg);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            console.log(err);
            // Alert.alert('', err);
          });
      }
    }
  };

  return (
    <div className="Stock_Container">
      <div className="top_bar">
        <img src={play} alt="" onClick={() => navigate(-1)} />
        <p className="title_text">Stock Details</p>
      </div>
      <div className="script_div">
        <div className="data_bs_part">
          <p className="data_bs_text">{item.symbol_display.charAt(0)}</p>
        </div>
        <div className="data_script_part">
          <p className="script_text">{item.symbol_display}</p>
          <p className="rate_text">
            {moment(i.expiry_date).format("DD-MMM-YYYY")}
          </p>
        </div>
        <div className="data_value_part">
          <p className="value_text">{ltp}</p>
          <p
            className="brok_text"
            style={{
              fontSize: 11,
              color: chng > 0 ? NewTheme.greencolor : NewTheme.redcolor,
            }}
          >
            {chng > 0 ? (
              <FaCircleArrowUp style={{ color: NewTheme.greencolor }} />
            ) : (
              <FaCircleArrowDown style={{ color: NewTheme.redcolor }} />
            )}
            {parseFloat(chng).toFixed(2)}{" "}
            {"(" + parseFloat(percent).toFixed(2) + "%)"}
          </p>
        </div>
      </div>
      <div className="high_low_div">
        <div>
          <p className="hl_title">LOW</p>
          <p className="hl_text">{low2}</p>
        </div>
        <div>
          <p className="hl_title">HIGH</p>
          <p className="hl_text">{high2}</p>
        </div>
      </div>
      <div className="market_pending_div">
        <div>
          <button
            style={{
              backgroundColor: option === 0 ? NewTheme.MainColor : "white",
              color: option === 0 ? "white" : NewTheme.MainColor,
            }}
            onClick={() => {
              setOption(0);
            }}
          >
            Market
          </button>
        </div>
        <div>
          <button
            style={{
              backgroundColor: option === 1 ? NewTheme.MainColor : "white",
              color: option === 1 ? "white" : NewTheme.MainColor,
            }}
            onClick={() => {
              setOption(1);
            }}
          >
            Pending
          </button>
        </div>
      </div>

      <div className="qty_box">
        {/* <p className="qty_text">Qty</p>
        <input
          className="qty_value"
          placeholder="Enter Desired Qty"
          type="text"
          name=""
          id=""
          onChange={onQtyHandler}
          value={qty}
        /> */}

        <div className="login_div" style={{ padding: 5 }}>
          <div className="login_title">Qty</div>

          <InputText
            type="number"
            style={{ borderWidth: 0, padding: 0, height: 25 }}
            placeholder="Enter Qty"
            onChange={onQtyHandler}
          />
        </div>
      </div>

      <div
        className="qty_box"
        style={{
          display: option === 1 ? "block" : "none",
          padding: 5,
        }}
      >
        <div className="login_title">At Price</div>

        <InputText
          type="number"
          style={{ borderWidth: 0, padding: 0, height: 25 }}
          placeholder="Enter At Price"
          onChange={(e) => setAtPrice(e.target.value)}
        />

        {/* <p className="qty_text">At Price</p>
        <input
          className="qty_value"
          placeholder="Enter At Price"
          type="text"
          name=""
          id=""
          onChange={(e) => setAtPrice(e.target.value)}
          value={atPrice}
        /> */}
      </div>
      <div className="buy_sell_div">
        <div>
          <button
            style={{ background: NewTheme.redcolor }}
            onClick={option == 0 ? SellFunction : SellPending}
          >
            <p>Sell</p> <p>{bid}</p>
          </button>
        </div>
        <div>
          <button
            style={{ background: NewTheme.greencolor }}
            onClick={option == 0 ? BuyFunction : BuyPending}
          >
            <p>Buy</p> <p>{ask}</p>
          </button>
        </div>
      </div>
      <div className="qty_div">
        <div className="qty_div_item">
          <h6 style={{ fontSize: 12 }}>
            {" "}
            {i.script_type == "fut" ? "MIN QTY" : "LOT SIZE"}
          </h6>
          <p>{i.script_type == "fut" ? i.min_qty : i.lot_size}</p>
        </div>
        <div className="qty_div_item">
          <h6 style={{ fontSize: 12 }}>Qty/Odrer</h6>
          <p>{i.punch_qty}</p>
        </div>
        <div className="qty_div_item">
          <h6 style={{ fontSize: 12 }}>TOTAL QTY</h6>
          <p>{i.total_qty}</p>
        </div>
      </div>
      <div className="position_bs_div">
        <div className="position_bs_div_item">
          <h6>Position</h6>
          <p
            style={{
              color: buysell == 0 ? NewTheme.greencolor : NewTheme.redcolor,
            }}
          >
            {buysell == 0 ? "" : "-"} {qtypos > 0 ? qtypos : 0}
          </p>
        </div>
        <div className="position_bs_div_item">
          {qtypos > 0 ? (
            <button onClick={PosClose}>CLOSE POSITION</button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default StockDetails;
