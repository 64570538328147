import React from "react";
import play from "../../Images/play.png";
import { useWindowSize } from "@uidotdev/usehooks";
import BillCard from "./BillCard";

export default function Bill() {
  const { height } = useWindowSize();
  return (
    <div
      className="Ledger_Container"
      style={{ background: "white", paddingTop: 10 }}
    >
      <div
        className="top_bar"
        style={{
          position: "fixed",
          background: "white",
          top: 0,
          paddingTop: 30,
          width: "100%",
          paddingBottom: 30,
        }}
        onClick={() => (window.location = "/profile")}
      >
        <img src={play} className="top_back_icon" alt="" />
        <p className="title_text">Download Bill</p>
      </div>

      <div
        style={{
          width: "100%",
          height: height - 30,
          overflowY: "scroll",
          marginTop: 50,
          padding: 5,
        }}
      >
        <BillCard data={0} />
        <BillCard data={1} />
        <BillCard data={2} />
        <BillCard data={3} />
      </div>
    </div>
  );
}
