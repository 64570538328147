import React, { useState } from 'react'

import play from "../../Images/play.png";
import { useNavigate } from 'react-router-dom'
import InputComp from '../Register/InputComp';
import config from '../../Theme/ThemeConfig';
import Backend from '../../Backend';

const NewTheme = config.NewTheme
const backend = new Backend()
export default function ForgotPassword() {

    const [otp, setOtp] = useState(false);
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [OtpValue, setOtpValue] = useState('');
    const navigate = useNavigate()


    const forgot_password_otp = () => {
        const data = {
            sr: config.server,
            mobile: mobile,
        };

        backend
            .password_get_otp(data)
            .then(r => {
                if (r.error == 'False') {
                    setOtp(true);
                } else {
                    alert(r.message);
                }
            })
            .catch(err => alert(err));
    };

    const set_password_server = () => {
        const data = {
            sr: config.server,
            mobile: mobile,
            otpvalue: OtpValue,
            password: password,
        };

        backend
            .forgot_password(data)
            .then(r => {
                if (r.error == 'False') {
                    alert(r.message);
                    navigate(-1)
                } else {
                    alert(r.message);
                }
            })
            .catch(err => alert(err));
    };


    return (
        <div
            className="Login_Container"

            style={{ background: "white", flexDirection: 'column' }}
        >

            <div style={{ display: 'flex', height: 50, zIndex: 99, borderColor: 'gray', alignItems: 'center', width: '100%' }}>
                <div onClick={() => navigate(-1)} style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <img src={play} style={{ width: 20, height: 20, transform: 'rotate(180deg)' }} alt="" />
                </div>
                <div style={{ flex: 1 }}>Forgot Password</div>
            </div>


            <div style={{ width: '100%', alignSelf: 'center', padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                <InputComp
                    disable={otp}
                    type="number"
                    placeholder="eg. 98xxx98xxx"
                    text="Registered Mobile Number"
                    onChangeText={setMobile}
                />
                {otp && (
                    <InputComp
                        placeholder="eg. XXXX"
                        text="Enter Otp Received On Mobile Number"
                        onChangeText={setOtpValue}
                    />
                )}
                {otp && (
                    <InputComp
                        placeholder="eg. john@1234"
                        text="Enter New Password"
                        onChangeText={setPassword}
                    />
                )}
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                {otp ? (
                    <button

                        style={{ background: NewTheme.MainColor }}
                        onClick={set_password_server}
                        className="login_btn"
                    >

                        Reset Password
                    </button>
                ) : (
                    <button

                        style={{ background: NewTheme.MainColor }}
                        onClick={forgot_password_otp}
                        className="login_btn"
                    >

                        Get Otp
                    </button>
                )}

            </div>
        </div>
    )
}
