import { React, useState, useEffect } from "react";
import logo from "../../Images/logo.png";
import profile from "../../Images/profile-user.png";
import profit from "../../Images/profit.png";
import hand from "../../Images/palm-of-hand.png";
import code from "../../Images/password-code.png";
import bank from "../../Images/bank-statement.png";
import logout from "../../Images/logout.png";
import coin from "../../Images/coin-stack.png";
import bar from "../../Images/pie-chart.png";
import plus from "../../Images/newplus.png";

import downarraow from "../../Images/down-arrow.png";
import { AiOutlineReload, AiOutlineWhatsApp } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";

import "./Profile.css";
import Account from "../account/Account";
import Navigation from "../navigation/Navigation";
// import { NewTheme } from "../../Theme/Theme";
import Marquee from "./Marquee";
import config from "../../Theme/ThemeConfig";
import Wallet from "../Wallet/Wallet";
import { useNavigate } from "react-router-dom";

const NewTheme = config.NewTheme;

const Profile = () => {
  const navigate = useNavigate();

  const [ipAddress, setIPAddress] = useState("");

  const [imageExists, setImageExists] = useState(true);

  const [wallet, setWallet] = useState({
    bal: 0,
    link: "http://google.com",
  });

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIPAddress(data.ip))
      .catch((error) => console.log(error));
  }, []);

  const Logout = () => {
    localStorage.clear();
    window.location = "/";
  };
  console.log(localStorage.getItem("server"));
  return (
    <>
      <div
        className="Profile_Container"
        style={{
          position: "relative",
          overflowX: "hidden",
          background: "#fdfdfde6",
        }}
      >
        <Wallet setwallet={(e) => setWallet(e)} />

        <p
          className="ip"
          style={{
            color: "black",
            fontWeight: "bold",
            marginRight: 5,
            marginTop: 5,
          }}
        >
          {ipAddress}
        </p>

        <div style={{ background: NewTheme.MainColor, padding: 10 }}>
          <div style={{ background: "white", padding: 10, borderRadius: 5 }}>
            <div
              style={{
                textAlign: "center",
                fontSize: 12,
                color: "gray",
                fontWeight: "bold",
              }}
            >
              Available Balance
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: 12,
                color: NewTheme.MainColor,
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginTop: 3,
              }}
            >
              <img
                src={coin}
                style={{ height: 20 }}
                className="top_back_icon"
                alt=""
              />
              <div style={{ alignSelf: "center", marginLeft: 3, fontSize: 18 }}>
                {wallet?.bal}
              </div>
            </div>

            <div
              onClick={() => {
                navigate("/ledger");
              }}
              style={{
                textAlign: "center",
                fontSize: 12,
                color: "black",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginTop: 10,
              }}
            >
              <img
                src={bar}
                style={{ height: 20 }}
                className="top_back_icon"
                alt=""
              />
              <div
                style={{ alignSelf: "center", marginLeft: 10, fontSize: 14 }}
              >
                View Statement
              </div>
            </div>

            <div
              style={{
                textAlign: "center",
                fontSize: 12,
                color: "black",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginTop: 10,
              }}
            >
              <div
                style={{
                  alignSelf: "center",
                  marginLeft: 10,
                  fontSize: 14,
                  color: "gray",
                }}
              >
                Client:{" "}
                {JSON.parse(localStorage.getItem("client_details")).username}{" "}
              </div>
            </div>
          </div>

          <div
            style={{
              background: "white",
              padding: 10,
              borderRadius: 5,
              marginTop: 5,
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <button
              onClick={() => {
                if (wallet.link) {
                  window.open(wallet.link, "_blank");
                } else {
                }
              }}
              style={{
                display: "flex",
                padding: 10,
                paddingLeft: 15,
                paddingRight: 15,
                background: "#019c6b",
                borderWidth: 0,
                borderRadius: 3,
              }}
            >
              <FiPlus color="white" size={25} />
              <div
                style={{
                  alignSelf: "center",
                  marginLeft: 5,
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Deposit
              </div>
            </button>

            <button
              onClick={() => navigate("/withdraw")}
              style={{
                display: "flex",
                padding: 10,
                paddingLeft: 15,
                paddingRight: 15,
                background: "#0393d0",
                borderWidth: 0,
                borderRadius: 3,
              }}
            >
              <AiOutlineReload color="white" size={25} />
              <div
                style={{
                  alignSelf: "center",
                  marginLeft: 5,
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Withdraw
              </div>
            </button>
          </div>
        </div>
        <div
          className="lower_box"
          style={{
            marginTop: 10,
            borderColor: NewTheme.MainColor,
            borderWidth: 1,
            borderStyle: "solid",
          }}
        >
          {/* <Marquee /> */}

          <div
            className="list_div"
            onClick={() => {
              navigate("/account");
            }}
          >
            <img
              style={{
                height: 25,
                width: 25,
                borderRadius: 5,
              }}
              src={config.login}
              alt=""
              className="list_icon"
            />

            <p className="list_text">Account Details</p>
            <img
              style={{ height: 25, width: 25 }}
              src={downarraow}
              alt=""
              className="list_next_icon"
            />
          </div>
          <div className="list_divider" />

          <div className="list_divider" />
          <div
            className="list_div"
            onClick={() => {
              navigate("/rejection");
            }}
          >
            <img
              style={{ height: 25, width: 25 }}
              src={hand}
              alt=""
              className="list_icon"
            />
            <p className="list_text">Rejection Logs</p>
            <img
              style={{ height: 25, width: 25 }}
              src={downarraow}
              alt=""
              className="list_next_icon"
            />
          </div>
          <div className="list_divider" />

          <div
            className="list_div"
            onClick={() => {
              navigate("/bill");
            }}
          >
            <img
              style={{ height: 25, width: 25 }}
              src={profit}
              alt=""
              className="list_icon"
            />
            <p className="list_text">Bill Download</p>
            <img
              style={{ height: 25, width: 25 }}
              src={downarraow}
              alt=""
              className="list_next_icon"
            />
          </div>
          <div className="list_divider" />
          <div
            className="list_div"
            onClick={() => {
              navigate("/changepass");
            }}
          >
            <img
              style={{ height: 25, width: 25 }}
              src={code}
              alt=""
              className="list_icon"
            />
            <p className="list_text">Change Password</p>
            <img
              style={{ height: 25, width: 25 }}
              src={downarraow}
              alt=""
              className="list_next_icon"
            />
          </div>
          <div className="list_divider" />

          <div onClick={Logout} className="list_div">
            <img
              style={{ height: 25, width: 25 }}
              src={logout}
              alt=""
              className="list_icon"
            />
            <p className="list_text">Logout</p>
            <img
              style={{ height: 25, width: 25 }}
              src={downarraow}
              alt=""
              className="list_next_icon"
            />
          </div>
        </div>

        <div
          style={{
            padding: 10,
            borderRadius: 5,
            marginTop: 5,
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {wallet?.phone ? (
            <button
              onClick={() => {
                if (wallet.link) {
                  window.open(
                    "https://api.whatsapp.com/send?phone=" + wallet.phone
                  );
                } else {
                }
              }}
              style={{
                display: "flex",
                padding: 10,
                paddingLeft: 15,
                paddingRight: 15,
                background: "#25D366",
                borderWidth: 0,
                borderRadius: 3,
              }}
            >
              <AiOutlineWhatsApp color="white" size={25} />
              <div
                style={{
                  alignSelf: "center",
                  marginLeft: 5,
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Contact Us
              </div>
            </button>
          ) : null}

          {wallet?.tnc ? (
            <button
              onClick={() => {
                if (wallet.tnc) {
                  window.open(wallet.tnc, "_blank");
                } else {
                }
              }}
              style={{
                display: "flex",
                padding: 10,
                paddingLeft: 15,
                paddingRight: 15,
                background: "#3344FF",
                borderWidth: 0,
                borderRadius: 3,
              }}
            >
              <div
                style={{
                  alignSelf: "center",
                  marginLeft: 5,
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Terms & Conditions
              </div>
            </button>
          ) : null}
        </div>
        <div className="space"></div>
      </div>
      <Navigation navScreen="profile" />
    </>
  );
};

export default Profile;
