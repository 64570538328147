import React, { useState } from "react";
import logo from "../../logo.svg";
import play from "../../Images/play.png";
import "./Login.css";
import Backend from "../../Backend";
import config from "../../Theme/ThemeConfig";
// import { NewTheme } from "../../Theme/Theme";

const NewTheme = config.NewTheme

const backend = new Backend();

const ChangePass = () => {
  const [loading, setLoading] = useState(false);
  const [current, Setcurrent] = useState("");
  const [newpass, Setnewpass] = useState("");
  const [confirm, Setconfirm] = useState("");

  const changePassword = () => {
    if (newpass == "") {
      window.alert("Password Can Not Be Blank");
    } else if (newpass !== confirm) {
      window.alert("Passwords Do Not Match");
    } else if (confirm.length < 6) {
      window.alert("Password contains Less Then 6 Letters");
    } else {
      //   setLoading(true);
      let data = {
        sv: localStorage.getItem("server"),
        id: localStorage.getItem("client"),
        jwt: localStorage.getItem("token"),
        password: confirm,
        current: current,
      };

      backend.changepassword(data).then((r) => {
        setTimeout(() => {
          setLoading(false);
          window.alert(
            "Your Account Will Logout Now Please Login With New Password"
          );
          // refRBSheet.current.open();
        }, 500);
        // // console.log(r);

        if (r.error == "False") {
          setTimeout(() => {
            setLoading(false);
            localStorage.clear();
            // props.isLogin(0);
            window.location = "/";
          }, 1500);
        }
      });
    }
  };

  return (
    <>
      <div className="ChangePass_Container">
        <div className="top_bar">
          <img
            src={play}
            alt=""
            onClick={() => (window.location = "/profile")}
          />
          <p className="title_text">Ledger Account</p>
        </div>
      </div>
      <div className="Login_Container" style={{ alignItems: "flex-start" }}>
        <div
          className="internal_login"
          style={{ justifyContent: "flex-start" }}
        >
          <div className="login_div">
            <div className="login_title">Current PassWord</div>
            <input
              value={current}
              onChange={(e) => Setcurrent(e.target.value)}
              type="password"
              name=""
              className="login_input"
              id=""
            />
          </div>

          <div className="login_div">
            <div className="login_title">New PassWord</div>
            <input
              value={newpass}
              onChange={(e) => Setnewpass(e.target.value)}
              type="password"
              name=""
              className="login_input"
              id=""
            />
          </div>

          <div className="login_div">
            <div className="login_title">Confirm Nnew Password</div>
            <input
              value={confirm}
              onChange={(e) => Setconfirm(e.target.value)}
              type="password"
              name=""
              className="login_input"
              id=""
            />
          </div>
          <button
            style={{ backgroundColor: NewTheme.MainColor }}
            onClick={changePassword}
            className="login_btn"
          >
            Change Password
          </button>
          <div className="space"></div>
        </div>
      </div>
    </>
  );
};

export default ChangePass;
