import React from "react";
import { FaCircleArrowDown } from "react-icons/fa6";
// import { NewTheme } from "../../Theme/Theme";
import moment from "moment";
import config from "../../Theme/ThemeConfig";

const NewTheme = config.NewTheme

export default function TradeaCard(props) {
  const i = props.data;
  return (
    <div
      key={i.id}
      style={{
        display: "flex",
        padding: 5,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <div
        style={{
          width: 60,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FaCircleArrowDown
          size={24}
          style={{
            color: i.buy_sell == "0" ? NewTheme.greencolor : NewTheme.redcolor,
            transform: i.buy_sell == 0 ? "rotate(225deg)" : "rotate(45deg)",
          }}
        />
        <div style={{ fontWeight: "bold", fontSize: 11 }}>{i.qty}</div>
      </div>
      <div style={{ flex: 2 }}>
        <div style={{ fontWeight: "bold", fontSize: 14, display: "flex" }}>
          {i.symbol}
          <div style={{ fontSize: 10, color: "gray", paddingLeft: 5 }}>
            {moment(i.expiry_date).format("DD-MMM")}
          </div>
        </div>

        <div
          style={{
            color: "gray",
            fontWeight: "bold",
            fontSize: 13,
            display: "flex",
            alignItems: "center",
          }}
        >
          {i.buy_sell == 0 ? "BUY" : "SELL"}

          <FaCircleArrowDown
            size={12}
            style={{
              color:
                i.buy_sell == "0" ? NewTheme.greencolor : NewTheme.redcolor,
              transform: "rotate(-90deg)",
              marginLeft: 5,
              marginRight: 5,
            }}
          />
          {i.rate}
          {/* {i.buy_sell == 0 ? bid : ask} */}
        </div>
        <div style={{ fontSize: 11, fontWeight: "bold", color: "gray" }}>
          {moment(i.date_created).format("DD-MMM HH:mm:ss")}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontSize: 16,
            fontWeight: "bold",
            color: i.profit_loss < 0 ? NewTheme.redcolor : NewTheme.greencolor,
          }}
        >
          {i.profit_loss}
          {/* {parseFloat(pf).toFixed(2)} */}
        </div>
        <div style={{ fontSize: 11, fontWeight: "bold", color: "gray" }}>
          Brok.{i.brokerage_amt}
        </div>
        <div style={{ fontSize: 13, fontWeight: "bold", color: "gray" }}>
          {i.trade_msg}
        </div>
      </div>
    </div>
  );
}
