import logo from "./logo.svg";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";

import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

import Watchlist from "./pages/watchlist/Watchlist";
import Watchitem from "./pages/watchlist/Watchitem";
import TabMenuDemo from "./pages/TabMenu";
import { TabMenu } from "primereact/tabmenu";
import { PrimeReactProvider } from "primereact/api";
import Profile from "./pages/profile/Profile";
import Account from "./pages/account/Account";
import Position from "./pages/position/Position";
import Trades from "./pages/trades/Trades";
import SiTp from "./pages/sitp/SiTp";
import DateFilter from "./pages/datefilter/DateFilter";
import Ledger from "./pages/ledger/Ledger";
import Rejection from "./pages/rejection/Rejection";
import Navigation from "./pages/navigation/Navigation";
import Login from "./pages/login/Login";
import io from "socket.io-client";
import SocketContext from "./SocketContext";
import ChangePass from "./pages/changepass/ChangePass";
import Search from "./pages/search/Search";
import StockDetails from "./pages/stockdetail/StockDetails";
import Bill from "./pages/Bill/Bill";
import News from "./pages/News/News";
import Rules from "./Rules";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import config from "./Theme/ThemeConfig";
import Rearrange from "./pages/ReArrange/Rearrange";
import Register from "./pages/Register/Register";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Withdraw from "./pages/Withdraw/Withdraw";
// import UseGestures from "./pages/UseGestures";
// import Pager from "./gesture/viewpager";

// import (`./resource/env/${process.env.REACT_APP_NAME}/env.scss`);

var socket = io('https://ue.utrade.today');

if (localStorage.getItem("watchselect") == null) {
  localStorage.setItem("watchselect", 0);
}
var sheet = true;

function App() {

  const value = {
    appendTo: "self",
  };

  useEffect(() => {
    // localStorage.setItem("rulesheet", "0");
  }, []);

  if (localStorage.getItem("client_details")) {
    var datas = JSON.parse(localStorage.getItem("client_details"));

    let send_data = {
      id: datas.id,
      server: datas.server,
      super_id: datas.super_id,
      master_id: datas.master_id,
      type: "Client",
    };
    socket.emit("login", send_data);
  }

  return (
    <div className="app">

      <Helmet>
        <meta charSet="utf-8" />
        <title>{config.helmetname}</title>
        <link rel="icon" href={config.helmetico} />
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>

      <SocketContext.Provider value={socket}>
        <BrowserRouter>
          {localStorage.IsLoggedIn !== "1" ? (
            <Routes>
              <Route
                path="/login"
                element={
                  <>
                    <Login />
                  </>
                }
              ></Route>
              <Route
                path="/register"
                element={
                  <>
                    <Register />
                  </>
                }
              ></Route>
              <Route
                path="/forgot"
                element={
                  <>
                    <ForgotPassword />
                  </>
                }
              ></Route>
              <Route
                path="*"
                element={
                  <>
                    <Login />
                  </>
                }
              ></Route>
            </Routes>
          ) : (
            <>
              <Rules
                state={sheet}
                close={() => {
                  sheet = false;
                }}
              />
              <Routes>
                <Route
                  path="/changepass"
                  element={
                    <>
                      <ChangePass />
                    </>
                  }
                ></Route>
                <Route
                  path="/search"
                  element={
                    <>
                      <Search />
                    </>
                  }
                ></Route>

                {/* <Route
              path="/gesture"
              element={
                <>
                  <UseGestures />
                </>
              }
            ></Route> */}
                {/* <Route
              path="/pager"
              element={
                <>
                  <Pager />
                </>
              }
            ></Route> */}

                <Route
                  path="/stock"
                  element={
                    <>
                      <StockDetails />
                    </>
                  }
                ></Route>

                <Route
                  path="/withdraw"
                  element={
                    <>
                      <Withdraw />
                    </>
                  }
                ></Route>

                <Route
                  path="/position"
                  element={
                    <>
                      <Position />
                      <Navigation navScreen="position" />
                    </>
                  }
                ></Route>
                <Route
                  path="/rejection"
                  element={
                    <>
                      <Rejection />
                    </>
                  }
                ></Route>

                <Route
                  path="/bill"
                  element={
                    <>
                      <Bill />
                    </>
                  }
                ></Route>
                <Route
                  path="/news"
                  element={
                    <>
                      <News />
                    </>
                  }
                ></Route>

                <Route
                  path="/navigation"
                  element={
                    <>
                      <Navigation />
                    </>
                  }
                ></Route>
                <Route
                  path="/datefilter"
                  element={
                    <>
                      <DateFilter />
                    </>
                  }
                ></Route>
                <Route
                  path="/ledger"
                  element={
                    <>
                      <Ledger />
                    </>
                  }
                ></Route>

                <Route
                  path="/rearrange"
                  element={
                    <>
                      <Rearrange />
                    </>
                  }
                ></Route>

                <Route
                  path="/sitp"
                  element={
                    <>
                      <Navigation navScreen="sitp" />
                      <SiTp />
                    </>
                  }
                ></Route>

                <Route
                  path="/trades"
                  element={
                    <>
                      <Trades />
                      <Navigation navScreen="trades" />
                    </>
                  }
                ></Route>

                <Route
                  path="/"
                  element={
                    <>
                      <Watchlist />
                      <Navigation navScreen="watchlist" />
                    </>
                  }
                ></Route>

                <Route
                  path="/account"
                  element={
                    <>
                      <Account />
                    </>
                  }
                ></Route>

                <Route
                  path="/profile"
                  element={
                    <>
                      <Profile />
                    </>
                  }
                ></Route>
                <Route
                  path="/tab"
                  element={
                    <>
                      <PrimeReactProvider value={value}>
                        <TabMenuDemo />
                      </PrimeReactProvider>
                    </>
                  }
                ></Route>

                <Route
                  path="*"
                  element={
                    <>
                      <Watchlist />
                      <Navigation navScreen="watchlist" />
                    </>
                  }
                ></Route>
              </Routes>
            </>
          )}
        </BrowserRouter>
      </SocketContext.Provider>
    </div>
  );
}

export default App;
