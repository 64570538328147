import { React, useState, useEffect } from "react";
import "./Rejection.css";
import logo from "../../logo.svg";
import play from "../../Images/play.png";
import Backend from "../../Backend";
import { useWindowSize } from "@uidotdev/usehooks";
import RejecttionCard from "./RejecttionCard";
const backend = new Backend();

const Rejection = () => {
  const [loading, setLoading] = useState(false);
  const [rejection, setRejection] = useState([]);
  const { height } = useWindowSize();

  const [rejectionSelected, setRejectionSelected] = useState("");

  useEffect(() => {
    loadRejection();
  }, []);

  const loadRejection = () => {
    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
    };
    try {
      backend
        .loadrejection(data)
        .then((r) => {
          console.log("r");
          console.log(r);
          setLoading(false);
          // console.log(r);
          if (r.error == "False") {
            setRejection(r.data);
            // setPosition(r.position);
            console.log("HIhi me khud");
            console.log(rejection);
            console.log("__-___");
          }
          if (r.error == "True") {
            console.log("error hy bhai");
          } else {
            // alert(r.message);
            console.log("not found");
          }
        })
        .then((e) => {
          console.log("error here");
        })
        .catch((e) => {
          console.log("Failed to Fetch");
        });
    } catch (e) {
      console.log("Error", e);
    }
  };
  console.log("position[0]");

  return (
    <div
      className="Ledger_Container"
      style={{ background: "white", paddingTop: 10 }}
    >
      <div
        className="top_bar"
        style={{
          position: "fixed",
          background: "white",
          top: 0,
          paddingTop: 30,
          width: "100%",
          paddingBottom: 30,
        }}
        onClick={() => (window.location = "/profile")}
      >
        <img src={play} className="top_back_icon" alt="" />
        <p className="title_text">Rejection Logs</p>
      </div>

      <div
        style={{
          width: "100%",
          height: height - 30,
          overflowY: "scroll",
          marginTop: 50,
        }}
      >
        {rejection &&
          rejection.map((item, index) => <RejecttionCard data={item} />)}
        <div style={{ background: "white", height: 70 }}></div>
      </div>
    </div>
  );
};

export default Rejection;
