import { React, useState, useCallback, useEffect, useRef } from "react";
import "./Watchlist.css";
import logo from "../../logo.svg";
import search_icon from "../../Images/magnifying-glass.png";

import arrow from "../../Images/arrow.png";

import coin from '../../Images/coin-stack.png'

import Watchitem from "./Watchitem";

import Backend from "../../Backend";
import StockDetails from "../stockdetail/StockDetails";
import { json } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { useWindowSize } from "@uidotdev/usehooks";
import Newsicon from "./Newsicon";
import config from "../../Theme/ThemeConfig";
import Wallet from "../Wallet/Wallet";
import { FiPlus } from "react-icons/fi";
import { FaArrowsUpDown } from "react-icons/fa6";

import { useNavigate } from 'react-router-dom'



const backend = new Backend();



const NewTheme = config.NewTheme

const Watchlist = () => {
  const [search, setSearch] = useState("");
  const [searchList, setSearchList] = useState([]);

  const [list, setList] = useState([]);
  const { height, width } = useWindowSize();

  const [selected, setSelected] = useState(list[0]);

  const [searchSelected, setSearchSelected] = useState("");
  const [loading, setLoading] = useState("");

  const [wallet, setWallet] = useState({
    bal: 0,
    link: 'http://google.com',
  });

  const navigate = useNavigate()

  useEffect(() => {
    loadwatchlistfirst();
  }, []);

  const loadwatchlistfirst = () => {
    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      filter: localStorage.getItem("watchselect"),
    };

    backend.loadwatchlist(data).then((r) => {
      setTimeout(() => {
        setLoading(false);
        // refRBSheet.current.open();
      }, 500);
      // console.log(r);

      if (r.error == "False") {
        // console.log(r.data);

        // props.setData(r.data);
        localStorage.setItem("watchlist", JSON.stringify(r.data));
        setList(r.data);
        // console.log("list", list);
      } else {
        localStorage.clear();
        window.location = "/";
      }
      // console.log(r);
    });
  };

  const [selectTab, setSelectTab] = useState(
    localStorage.getItem("watchselect")
  );


  return (
    <div className="Watchlist_Container" style={{
      height: height - 60,
    }}>

      <Wallet setwallet={e => setWallet(e)} />
      <div style={{ height: 40, background: NewTheme.MainColor, display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 1, color: "white", fontWeight: 'bold', fontSize: 14, paddingLeft: 5, display: 'flex' }}>
          <img src={coin} style={{ height: 20 }} className="top_back_icon" alt="" />
          <div style={{ paddingLeft: 10 }}>{wallet.bal}</div>
        </div>


        <div
          style={{ display: 'flex', marginRight: 10, borderWidth: 1, borderColor: 'white', borderStyle: 'solid', padding: 4, paddingLeft: 7, paddingRight: 7, borderRadius: 3 }}
          onClick={() => {
            if (wallet.link) {
              window.open(wallet.link, "_blank")
            } else {

            }
          }}
        >
          <FiPlus color="white" size={20} />
          <div style={{ alignSelf: 'center', marginLeft: 5, fontSize: 13, fontWeight: 'bold', color: 'white' }}>Add Funds</div>
        </div>





      </div>

      <div
        style={{
          height: 50,
          display: "flex",
          paddingRight: 10,
          boxSizing: "border-box",
          alignItems: "center",
          paddingLeft: 5,
        }}
      >
        <div
          onClick={() => navigate("/search")}
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: "solid",
            height: 40,
            borderRadius: 5,
            borderColor: "gray",
          }}
        >
          <img
            src={search_icon}
            style={{ height: 20, width: 20, marginLeft: 10, marginRight: 10 }}
          />

          <div
            style={{
              height: 35,
              borderWidth: 0,
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            Search Script Here
          </div>
        </div>

        <Newsicon />
        <div onClick={() => navigate('/rearrange')} style={{ paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <FaArrowsUpDown color={NewTheme.MainColor} size={20} />
        </div>
      </div>


      <div className="menu_bar" >
        <div className="inter_menu_bar" style={{ background: NewTheme.lightmain }}>
          <div
            className="menu_box"
            style={{
              borderBottomWidth: selectTab == 0 ? 2 : 0,
              borderBottomColor: NewTheme.MainColor
            }}
            onClick={() => {
              localStorage.setItem("watchselect", 0);
              setSelectTab(0);
              loadwatchlistfirst();

              localStorage.setItem("selectTab", 0);
            }}
          >
            <p className="menu_text">First</p>
          </div>
          <div
            className="menu_box"
            style={{
              borderBottomWidth: selectTab == 1 ? 2 : 0,
            }}
            onClick={() => {
              localStorage.setItem("watchselect", 1);
              setSelectTab(1);
              loadwatchlistfirst();
              localStorage.setItem("selectTab", 1);
            }}
          >
            <p className="menu_text">Second</p>
          </div>
          <div
            className="menu_box"
            style={{
              borderBottomWidth: selectTab == 2 ? 2 : 0,
            }}
            onClick={() => {
              localStorage.setItem("watchselect", 2);
              setSelectTab(2);
              loadwatchlistfirst();
              localStorage.setItem("selectTab", 2);
            }}
          >
            <p className="menu_text">Third</p>
          </div>
        </div>
      </div>


      <div
        style={{
          display: "flex",
          fontWeight: "bold",
          padding: 5,
          borderStyle: "solid",
          borderWidth: 0,
          paddingTop: 0,

          borderBottomWidth: 1,
        }}
      >
        <div style={{ flex: 2 }}>Script</div>
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          BID
        </div>
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          ASK
        </div>
      </div>



      <div
        style={{
          height: height - 230,
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {list &&
          list.map((item, index) => {
            return (
              <div
                className="exterior"
                onClick={() => {
                  localStorage.setItem("item", JSON.stringify(item));
                  navigate("/stock")
                }}
              >
                <Watchitem item={item} />
              </div>
            );
          })}

      </div>
    </div>
  );
};

export default Watchlist;
