// config.js
const appid = window.location.host;
const getAppId = () => {
  // Logic to retrieve the app ID or similar identifier
  // For example, you might use Platform.constants.androidID for Android
  // or a similar mechanism for other platforms
  return appid; // Replace this with your actual logic
};

var config = {};



const appId = getAppId();




const response = await fetch("/config.json");
const data = await response.json();
config = data[appId]



export default config;
