import React, { useEffect, useState } from 'react'
import Backend from '../../Backend'
import Segments from './Segments'
const backend = new Backend()
export default function Allscripts() {

    const [data, setData] = useState([])
    const [data2, setData2] = useState([])
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        LoadScripts()
    }, [])

    const LoadScripts = () => {

        setLoading(true);

        let data = {
            sv: localStorage.getItem("server"),
            id: localStorage.getItem("client"),
            jwt: localStorage.getItem("token"),
            filter: '0',
        };
        backend.load_all_scripts(data).then(r => {

            if (r.error == "False") {
                setData(r.data)
                setData2(r.name_array)

            } else {

                alert(r.message)
            }

        }).catch(err => console.log(err))

    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 10 }}>

            {data.length > 0 ? data.map((i, t) => (
                i.length > 0 &&
                <Segments data={i} name={data2[t].name} t={t} />
            )) : null}



        </div>
    )
}
