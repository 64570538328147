const iState = {
  pfdata: [],
  isLogged: 0,
  token: "",
  server: "",
  id: "",
  theme: 1, // 0 dark 1 light
  selected_script: "",
  filter: 0,
  password: 0,
  broker_amt: 0,
  sub_broker_amt: 0,
  total: 0,
  btotal: 0,
  demo: 0,
};

const Reducer = (state = iState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isLogged: action.payload,
      };
    case "TOKEN":
      return {
        ...state,
        token: action.payload,
      };
    case "ID":
      return {
        ...state,
        id: action.payload,
      };
    case "SERVER":
      return {
        ...state,
        server: action.payload,
      };
    case "THEME":
      return {
        ...state,
        theme: action.payload,
      };
    case "WATCHLIST_SELECT":
      return {
        ...state,
        selected_script: action.payload,
      };
    case "PASSWORD":
      return {
        ...state,
        password: action.payload,
      };
    case "DEMO":
      return {
        ...state,
        demo: action.payload,
      };
    case "CLEAR_PF":
      return {
        ...state,
        pfdata: [],
        total: 0,
      };
    case "PF": {
      if (action.total > 0) {
        var newpfdata = state.pfdata;

        if (Array.isArray(newpfdata)) {
          var found = false;
          for (var i = 0; i < newpfdata.length; i++) {
            if (newpfdata[i].script_id == action.script_id) {
              found = i;
              continue;
            }
          }
          if (found === false) {
            newpfdata.push({
              script_id: action.script_id,
              pf: action.pf,
              btotal: action.btotal,
            });
            var total = 0;
            var btotalnew = 0;
            newpfdata.map((i) => {
              total = parseFloat(total) + parseFloat(i.pf);
              btotalnew = parseFloat(btotalnew) + parseFloat(i.btotal);
            });
            return {
              ...state,
              pfdata: newpfdata,
              total: total,
              btotal: btotalnew,
            };
          } else {
            newpfdata[found].pf = action.pf;
            newpfdata[found].btotal = action.btotal;
            var total = 0;
            var btotalnew = 0;
            newpfdata.map((i) => {
              total = parseFloat(total) + parseFloat(i.pf);
              btotalnew = parseFloat(btotalnew) + parseFloat(i.btotal);
            });
            return {
              ...state,
              pfdata: newpfdata,
              total: total,
              btotal: btotalnew,
            };
          }
        } else {
          newpfdata.push({
            script_id: action.script_id,
            pf: action.pf,
            btotal: action.btotal,
          });
          return {
            ...state,
            pfdata: newpfdata,
            total: action.pf,
            btotal: btotalnew,
          };
        }
      }
    }

    default:
      return { ...state };
  }
};

export default Reducer;
