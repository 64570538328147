// import React, { useState } from 'react';
// import styled from 'styled-components';

// const DraggableListContainer = styled.div`
//   ul {
//     list-style: none;
//     padding: 0;
//   }
// `;

// const DraggableListItem = styled.li`
//   margin: 8px 0;
//   padding: 8px;
//   background-color: #f0f0f0;
//   cursor: pointer;
// `;

// const DraggableList = () => {
//     const [items, setItems] = useState([
//         { id: 1, content: 'Item 1' },
//         { id: 2, content: 'Item 2' },
//         { id: 3, content: 'Item 3' },
//         { id: 4, content: 'Item 4' },
//     ]);

//     const [draggedIndex, setDraggedIndex] = useState(null);





//     return (
//         <DraggableListContainer>
//             <h2>Draggable List</h2>
//             <ul>
//                 {items.map((item, index) => (
//                     <DraggableListItem
//                         key={item.id}
//                         draggable
//                         onDragStart={(e) => handleDragStart(e, index)}
//                         onDragOver={handleDragOver}
//                         onDrop={(e) => handleDrop(e, index)}
//                     >
//                         {item.content}
//                     </DraggableListItem>
//                 ))}
//             </ul>
//         </DraggableListContainer>
//     );
// };

// export default DraggableList;


import React, { useState } from 'react'
import play from "../../Images/play.png";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Backend from '../../Backend';


const DraggableListItem = styled.div`
  /* margin: 8px 0; */
  /* padding: 8px; */
  /* background-color: #f0f0f0; */
  cursor: pointer;
`;

const backend = new Backend()

export default function Rearrange() {

    const navigate = useNavigate()
    const [wacthlist, SetWatchlist] = useState(
        JSON.parse(localStorage.getItem("watchlist"))
    );
    const [loading, setLoading] = useState(false)

    const [draggedIndex, setDraggedIndex] = useState(null);
    const handleDrop = (e, dropIndex) => {
        const dragIndex = parseInt(e.dataTransfer.getData('index'));
        const draggedItem = wacthlist[dragIndex];

        const newItems = wacthlist.filter((item, index) => index !== dragIndex);
        newItems.splice(dropIndex, 0, draggedItem);

        SetWatchlist(newItems);
        setDraggedIndex(null);
    };


    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('index', index.toString());
        setDraggedIndex(index);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const sortByName = () => {
        const sortedList = [...wacthlist].sort((a, b) =>
            a.symbol_display.localeCompare(b.symbol_display),
        );

        sortedList.map((i, t) => {
            sortedList[t].series = t + 1;
        });

        SetWatchlist(sortedList);
    };


    const savewatchlist = () => {

        var senddata = []
        wacthlist.map((i, t) => {
            i.series = t + 1;
            senddata.push(i)
        })
        setLoading(true);
        let data = {
            sv: localStorage.getItem("server"),
            id: localStorage.getItem("client"),
            jwt: localStorage.getItem("token"),
            datas: wacthlist,
        };

        backend
            .rearrangewatchlist(data)
            .then(r => {
                setTimeout(() => {
                    setLoading(false);
                    // refRBSheet.current.open();
                }, 100);

                if (r.error == 'False') {
                    alert(r.message);
                    //code here
                } else {
                    alert(r.message);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <div

            style={{ background: "white" }}
        >

            <div style={{ display: 'flex', height: 50, zIndex: 99, borderBottomStyle: 'solid', borderColor: 'gray', borderWidth: 0.5, alignItems: 'center' }}>
                <div onClick={() => navigate(-1)} style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <img src={play} style={{ width: 20, height: 20, transform: 'rotate(180deg)' }} alt="" />
                </div>
                <div style={{ flex: 1 }}>ReArrenge Scripts</div>

                <div onClick={sortByName} style={{ padding: 10, borderStyle: 'solid', marginRight: 5, borderWidth: 1, borderRadius: 3 }}>
                    A-Z
                </div>
                <div onClick={savewatchlist} style={{ padding: 10, borderStyle: 'solid', marginRight: 5, borderWidth: 1, borderRadius: 3 }}>
                    Save
                </div>

            </div>

            <div style={{ padding: 5 }}>
                {wacthlist.map((item, index) => (
                    <DraggableListItem
                        key={item.id}
                        draggable
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, index)}
                    >
                        {/* {item.symbol_display} */}
                        <div style={{ borderStyle: 'solid', marginBottom: 10, padding: 10, boxSizing: 'border-box' }}>{item.symbol_display}</div>

                    </DraggableListItem>
                ))}

            </div>
        </div >
    )
}

