import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';

// Input component
const InputComp = ({ onChangeText, placeholder, text, type, max }) => {
    return (
        <div className="login_div" style={{ borderStyle: 'solid', marginBottom: 5, padding: 5, borderRadius: 5, borderWidth: 0.5 }}>
            <div className="login_title">{text}</div>


            <InputText
                type={type}
                style={{ borderWidth: 0, padding: 0, height: 25 }}
                placeholder={placeholder}
                max={max}
                onChange={(e) => onChangeText(e.target.value)}
            />
        </div>
    );
};

export default InputComp