import { React, useEffect, useState } from "react";

import "./Search.css";
import logo from "../../logo.svg";
import play from "../../Images/play.png";

import Backend from "../../Backend";
import moment from "moment";
import Searchitem from "./Searchitem";
import { InputText } from "primereact/inputtext";

// import { NewTheme } from "../../Theme/Theme";
import Searchcard from "./Searchcard";
import Searchcard2 from "./SearchCard2";
import config from "../../Theme/ThemeConfig";
import { FaFolderOpen } from "react-icons/fa6";
import Allscripts from "./Allscripts";
import Segments from "./Segments";
const backend = new Backend();

const NewTheme = config.NewTheme;

const Search = () => {
  const [search, setSearch] = useState("");
  const [wacthlist, SetWatchlist] = useState(
    JSON.parse(localStorage.getItem("watchlist"))
  );


  const [namedata, sernamedata] = useState([])

  const [searchdata, setSearchData] = useState([]);

  const [searchList, setSearchList] = useState([]);
  const [searchfoList, setSearchfoList] = useState([]);
  const [searchfutList, setSearchfutList] = useState([]);
  const [searchmcxList, setSearchmcxList] = useState([]);

  const [searchforexList, setSearchforexList] = useState([]);
  const [searchcryptoList, setSearchcryptoList] = useState([]);
  const [searchusList, setSearchusList] = useState([]);

  const [allscript, setAllscript] = useState(false);
  const [idList, setIdList] = useState([]);

  const [futfolder, setfutfolder] = useState(false);
  const [mcxfolder, setmcxfolder] = useState(false);
  const [optionfolder, setoptionfolder] = useState(false);

  const [cryptoFolder, setcryptoFolder] = useState(false);
  const [forexFolder, setforexFolder] = useState(false);
  const [UsFolder, setUsFolder] = useState(false);

  const [list, setList] = useState(
    typeof localStorage.localSearch === "undefined"
      ? []
      : JSON.parse(localStorage.localSearch)
  );

  const [selected, setSelected] = useState(list[0]);

  const [searchSelected, setSearchSelected] = useState("");

  var a = [];

  const Search_Script = (e) => {
    setSearch(e.target.value);

    if (e.target.value.length > 1) {
      let data = {
        sv: localStorage.getItem("server"),
        id: localStorage.getItem("client"),
        jwt: localStorage.getItem("token"),
        filter: e.target.value,
      };

      backend.searchscript(data).then((r) => {
        if (r.error == "False") {
          // setSearchfutList(r.data.fut);
          // setSearchmcxList(r.data.mcx);
          // setSearchfoList(r.data.fo);

          // setSearchforexList(r.data.forex);
          // setSearchcryptoList(r.data.crypto);
          // setSearchusList(r.data.us);


          setSearchData(r.newdata);
          sernamedata(r.name_array);
          // setSearchData(r.data);
          // setSearchList(r.datas);
          // setSearchSelected(r.datas[0].id);
        } else {
          // window.alert(r.message);
        }
        // console.log(r);
      });
    } else {
      setSearchData([]);
    }
  };

  const [isPresent, setIsPresent] = useState(false);
  const updateValue = (id) => {
    // console.log("Inside");

    let a = idList.indexOf(parseInt(id));
    setIsPresent(a);
  };
  // console.log(mcxfolder)

  return (
    <div className="Search_Container">
      <div
        style={{
          height: 50,
          display: "flex",
          paddingRight: 10,
          boxSizing: "border-box",
          alignItems: "center",
          paddingLeft: 5,
          width: "100%",
          borderStyle: "solid",
          borderWidth: 0,
          borderBottomWidth: 1,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: "solid",
            height: 40,
            borderRadius: 5,
            borderColor: "gray",
            // boxSizing: "border-box",
            // marginLeft: 5,
            // paddingLeft: 10,
            // paddingRight: 10,
          }}
        >
          {/* <img
              src={search_icon}
              alt=""
              className="search_icon"
              style={{ height: 20, width: 20 }}
            /> */}
          <img
            onClick={() => (window.location = "/")}
            src={play}
            style={{
              height: 20,
              width: 20,
              marginLeft: 10,
              marginRight: 10,
              transform: "rotate(180deg",
            }}
          />

          <InputText
            value={search}
            onChange={Search_Script}
            placeholder="SEARCH SYMBOL"
            style={{
              height: 35,
              borderWidth: 0,
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          />
        </div>
        <div
          onClick={() => setAllscript(!allscript)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            paddingLeft: 10,
            paddingRight: 10,
            color: NewTheme.MainColor,
            fontWeight: "bold",
          }}
        >
          {allscript ? "Watchlist" : "All Scripts"}
        </div>
      </div>

      {allscript ? (
        <Allscripts />
      ) : searchdata.length > 0
        ? null : (
          wacthlist.map((i, t) => <Searchcard key={i.script_id} data={i} />)
        )}

      <div className="data_table">

        {searchdata.length > 0 ? searchdata.map((i, t) => (
          i.length > 0 &&
          <Segments data={i} name={namedata[t].name} t={t} />
        )) : null}
        {/* {searchList &&
          searchList.map((item, index) => (
            <Searchitem item={item} index={index} />
          ))} */}


      </div>
    </div>
  );
};

export default Search;
