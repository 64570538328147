import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import play from "../../Images/play.png";

import InputComp from "./InputComp"
import config from '../../Theme/ThemeConfig';
import Backend from '../../Backend';


const backend = new Backend()
const NewTheme = config.NewTheme
export default function Register() {

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false);
    const [otpsent, setOptSent] = useState(false);
    const [Username, setUSername] = useState('');
    const [Password, setPassword] = useState('');
    const [Mobile, setMobile] = useState('');
    const [RefCode, setRefCode] = useState('');
    const [Cpassword, setCpassword] = useState('');
    const [Name, setName] = useState('');
    const [OtpValue, setOtpValue] = useState('');
    const [resend, setResend] = useState(false);
    const [resendTime, setResendTime] = useState(60);



    const User_register = () => {
        if (Name == '') {
            alert('Enter Name');
        } else if (Username == '') {
            alert('Enter Username');
        } else if (Password == '') {
            alert('Enter Password');
        } else if (Password !== Cpassword) {
            alert('Password Do Not Match');
        } else {
            //   setLoading(true);
            let data = {
                un: Username,
                pw: Password,
                sr: config.server,
                device: 'web',
                version: '22',
                mobile: Mobile,
                refcode: RefCode,
                name: Name,
            };
            backend
                .Register(data)
                .then(r => {

                    // setLoading(false);
                    if (r.error == 'False') {
                        alert(r.message);
                        navigate(-1);


                    } else {
                        alert(r.message);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };

    const verify_register = () => {
        if (OtpValue == '') {
            alert('Enter OTP');
        } else {
            //   setLoading(true);
            let data = {
                un: Username,
                pw: Password,
                sr: config.server,
                device: 'web',
                version: '22',
                mobile: Mobile,
                refcode: RefCode,
                name: Name,
                otpvalue: OtpValue,
            };
            backend
                .Verify_register(data)
                .then(r => {
                    // setLoading(false);
                    if (r.error == 'False') {
                        alert(r.message);
                        navigate(-1);
                    } else {
                        alert(r.message);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };


    return (
        <div
            className="Login_Container"

            style={{ background: "white", flexDirection: 'column' }}
        >

            <div style={{ display: 'flex', height: 50, zIndex: 99, borderColor: 'gray', alignItems: 'center', width: '100%' }}>
                <div onClick={() => navigate(-1)} style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <img src={play} style={{ width: 20, height: 20, transform: 'rotate(180deg)' }} alt="" />
                </div>
                <div style={{ flex: 1 }}>Create New Account</div>



            </div>
            <div style={{ width: '100%', alignSelf: 'center', padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                {!otpsent && (
                    <InputComp
                        onChangeText={setUSername}
                        placeholder="eg. john01"
                        text="Username"
                    />
                )}
                {!otpsent && (
                    <InputComp
                        onChangeText={setName}
                        placeholder="eg.Jhon Dow"
                        text="Name"
                    />
                )}
                {!otpsent && (
                    <InputComp
                        onChangeText={setMobile}
                        placeholder="eg.9988776655"
                        text="Mobile Number"
                        type="number"
                        max={'10'}
                    />
                )}
                {!otpsent && (
                    <InputComp
                        onChangeText={setPassword}
                        placeholder="eg. abcd@1234"
                        text="Enter Password"
                        type="password"
                    />
                )}
                {!otpsent && (
                    <InputComp
                        onChangeText={setCpassword}
                        placeholder="******"
                        text="Re-Enter Password"
                        type="password"
                    />
                )}
                {!otpsent && (
                    <InputComp
                        onChangeText={setRefCode}
                        placeholder="eg. ABC123"
                        text="REF CODE (Optional)"
                    />
                )}

                {otpsent && (
                    <InputComp
                        onChangeText={setOtpValue}
                        placeholder="eg.5467"
                        text="One Time Password(OTP)"
                        type="number"
                    />
                )}



            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <button

                    style={{ background: NewTheme.MainColor }}
                    onClick={User_register}
                    className="login_btn"
                >

                    Create New Account
                </button>
            </div>

        </div>
    )
}
