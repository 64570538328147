import React from "react";
import "./DateFilter.css";

const DateFilter = () => {
  return (
    <div className="DateFilter_Container">
      <p className="title_text">Select Date Range</p>
      <p className="input_text">
        Today <span>10-12-122</span>
      </p>
      <p className="input_text">
        This Week 
        <span>10-12-122</span>
    {/* <input placeholder="10-09-09" type="datetime-local" className="date_input" name="" id="" /> */}
      </p>
      <p className="title_text">Select Date Range</p>
      <p className="input_text">
        Today <span>10-12-122</span>
      </p>
      <p className="input_text">
        This Week <span>10-12-122</span>
      </p>
      <button type="submit" >Submit</button>
      <div className="space"></div>
    </div>
  );
};

export default DateFilter;
