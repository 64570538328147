import { React, useState } from "react";
// import logo from '../logo.svg'
import logo from "../../Images/logo.png";
import "./Login.css";

import Backend from "../../Backend";
import { InputText } from "primereact/inputtext";
import config from "../../Theme/ThemeConfig";

import { useNavigate } from "react-router-dom";
import Demo from "./Demo";
// import { NewTheme } from "../../Theme/Theme";
const NewTheme = config.NewTheme;
const backend = new Backend();

const Login = () => {
  const [server, setserver] = useState(config.server);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [demo, setDemo] = useState(false);

  const [imageExists, setImageExists] = useState(true);

  const navigate = useNavigate();

  const login_demo = (a, b, c) => {
    // setLoading(true);
    let data = {
      un: b,
      pw: c,
      sr: a,
      fcm: "",
      ip: "",
      device: "webios",
      version: localStorage.getItem("version"),

      // fcm: fcmtoken,
      // ip: ip,
    };
    backend.login(data).then((r) => {
      // setLoading(false);
      if (r.error == "False") {
        localStorage.setItem("token", r.jwt);
        localStorage.setItem("rulesheet", "0");
        localStorage.setItem("server", r.sv);
        localStorage.setItem("client", r.users_id);
        localStorage.setItem("client_details", JSON.stringify(r.users));
        localStorage.setItem("IsLoggedIn", "1");

        localStorage.setItem("IsLoggedIn", "1");
        window.location = "/";
      } else {
        alert(r.message);
      }
    });
  };

  const login = () => {
    if (server == "") {
      window.alert("Enter Server");
    } else if (username == "") {
      window.alert("Enter Username");
    } else if (password == "") {
      window.alert("Enter Password");
    } else {
      // setLoading(true);
      let data = {
        un: username,
        pw: password,
        sr: server,
        // fcm: fcmtoken,
        // ip: ip,
        device: "webios",
        version: localStorage.getItem("version"),
      };

      console.log(data);
      backend.login(data).then((r) => {
        // setLoading(false);
        if (r.error == "False") {
          localStorage.setItem("token", r.jwt);
          localStorage.setItem("rulesheet", "0");
          // localStorage.setItem('rules', JSON.stringify(r.rules));
          localStorage.setItem("server", r.sv);
          localStorage.setItem("client", r.users_id);
          localStorage.setItem("client_details", JSON.stringify(r.users));
          localStorage.setItem("IsLoggedIn", "1");
          // props.token(r.jwt);
          // props.id(r.users_id);
          // props.server(r.sv);
          // props.isLogin(1);
          localStorage.setItem("IsLoggedIn", "1");
          window.location = "/";
        } else {
          alert(r.message);
        }
      });
    }
  };

  return (
    <div className="Login_Container">
      {demo ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            width: "100%",
            height: "100%",
            background: "white",
          }}
        >
          <Demo login_demo={login_demo} closedemo={() => setDemo(false)} />
        </div>
      ) : null}
      <div className="internal_login" style={{ padding: 10 }}>
        <img
          src={config.login}
          style={{ height: config.loginsize[0], width: config.loginsize[1] }}
          alt=""
          className="login_logo"
        />

        <div className="login_div">
          <div className="login_title">User Name</div>

          <InputText
            style={{ borderWidth: 0, padding: 0, height: 25 }}
            placeholder="Enter Username"
            onChange={(e) => setusername(e.target.value)}
          />
        </div>

        <div className="login_div">
          <div className="login_title">Password</div>

          <InputText
            type="password"
            style={{ borderWidth: 0, padding: 0, height: 25 }}
            placeholder="Enter your password"
            onChange={(e) => setpassword(e.target.value)}
          />
        </div>

        <div
          onClick={() => navigate("/forgot")}
          style={{
            alignSelf: "flex-end",
            fontSize: 13,
            fontWeight: "bold",
            color: NewTheme.MainColor,
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 4,
          }}
        >
          Forgot Password
        </div>
        <button
          style={{ background: NewTheme.MainColor }}
          onClick={() => login()}
          className="login_btn"
        >
          Login
        </button>

        <button
          style={{ background: NewTheme.MainColor }}
          onClick={() => navigate("/register")}
          className="login_btn"
        >
          Create New Account
        </button>
        <p className="login_demo_text">
          Want To Login With Demo Account?{" "}
          <span
            onClick={() => setDemo(true)}
            style={{
              color: NewTheme.MainColor,
              fontWeight: "bold",
            }}
          >
            Click Here
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
